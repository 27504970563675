<template>
  <div class="page-content">
    <page-breadcrumb title="IS-Tag Management" class="mb-1" />
    <h6>Total: {{ total_items || 0 }} | Success: {{ total_success || 0 }} | Failed: {{ total_fail || 0 }}</h6>
    <data-table-ssr id="tag_list" ref="tag_list"
      :get-list-fn="getList" :columns="tag_columns"
      :filter_schema="filter_schema"
    />
  </div>
</template>
<script>
import { buildSelectOptions, removeEmpty } from "@core/utils/index";
import service from "../service";
import { STATUS } from "../constants";

const STATUS_OPTIONS = buildSelectOptions(STATUS);

const tag_columns = [
  { label: 'Day', field: 'createdAt', input_type: "date" },
  { label: 'Tag', field: 'tag_id', type: 'number' },
  { label: 'Email', field: 'email' },
  { label: 'Type', field: 'type' },
  { label: 'Status', field: 'is_successed', input_type: 'select', options: STATUS_OPTIONS },
];
const filter_schema = [
  { cols: 4, fields: [{ label: 'Filter date', field: 'time_range', input_type: 'date_range' }] },
  { cols: 3, fields: [{ label: 'Search tag', field: 'search_tag', input_type: 'number' }] },
  { cols: 3, fields: [{ label: 'Search email', field: 'search_email', input_type: 'email' }] },
  { cols: 2, fields: [{ label: 'Status', field: 'selected_status', input_type: 'select', options: STATUS_OPTIONS }] },
];

export default {
  data(){
    return{
      tag_columns, filter_schema,
      total_items: null,
      total_success: null,
      total_fail: null,
      status_options:[
        {text: "All", value: null},
        {text: "Success", value: true},
        {text: "Fail", value: false},
      ],
    }
  },
  methods: {
    async getList({ limit, page, query: table_filter }) {
      let query = removeEmpty({
        tag_id: table_filter.search_tag,
        is_successed: table_filter.selected_status,
        email: table_filter.search_email,
      });
      let time_from, time_to;
      if(table_filter.time_range) {
        [time_from, time_to] = table_filter.time_range.split(' to ');
        if(!time_to) { time_to = time_from; }
      }
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        time_from,
        time_to,
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
        this.total_items = response.data.data.total;
        this.total_success = response.data.data.total_success;
        this.total_fail = response.data.data.total_fail;
      }
      return { list, total }
    },
  }
}
</script>
